import { Button, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { getWithExpiry } from "../../custom_functions/getSetWithExpiry";

export default function Profile() {
  function setWithExpiry(key, value, ttl) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  const [user, setUser] = useState({
    name: getWithExpiry("auth_user_name"),
    email: getWithExpiry("auth_user_email"),
  });
  const handleInput = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/account", user).then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          setWithExpiry("auth_user_name", user.name, 432000000);
          setWithExpiry("auth_user_email", user.email, 432000000);
          window.location.reload();
        }
      });
    });
  };
  return (
    <div className="p-12">
      <h1 className="text-4xl">Profile</h1>
      <div className="flex justify-center p-5 space-x-5">
        <form
          className="flex flex-col lg:w-1/2 space-y-4 bg-white py-20 px-10 shadow"
          onSubmit={handleSaveChanges}
          noValidate
        >
          <TextField
            label="Full Name"
            size="small"
            name="name"
            value={user.name}
            onChange={handleInput}
          />
          <TextField
            label="Email"
            size="small"
            name="email"
            value={user.email}
            onChange={handleInput}
          />
          <Button variant="contained" color="success" type="submit">
            Save Changes
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              window.location.replace("/reset-password");
            }}
          >
            Change Password
          </Button>
        </form>
      </div>
    </div>
  );
}
