import { Button, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

export default function ResetPassword() {
  const [resetPassword, setResetPassword] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleInput = (e) => {
    setResetPassword({
      ...resetPassword,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/password", resetPassword).then((res) => {
        if (res.status === 200) {
          window.location.replace("/dashboard/main");
          alert(res.data.message);
        }
      });
    });
  };
  return (
    <div
      className="flex h-screen justify-center items-center"
      style={{ backgroundColor: "rgb(238, 238, 238)" }}
    >
      <form onSubmit={handleChangePassword} noValidate>
        <div className="flex flex-col justify-center pt-5 pb-8 px-12 border shadow-md bg-white space-y-4">
          <h2 className="text-2xl font-semibold">Reset Password</h2>
          <TextField
            label="New Password"
            name="password"
            type="password"
            variant="outlined"
            size="small"
            sx={{ width: 300 }}
            value={resetPassword.password}
            onChange={handleInput}
          />
          <TextField
            label="Confirm Password"
            name="password_confirmation"
            type="password"
            variant="outlined"
            size="small"
            sx={{ width: 300 }}
            value={resetPassword.password_confirmation}
            onChange={handleInput}
          />
          <Button
            style={{ backgroundColor: "rgb(34, 197, 94)" }}
            variant="contained"
            size="large"
            type="submit"
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
}
