import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import MainMenu from "../components/MainMenu";
import Dashboard from "./main/Dashboard";

// icons
import axios from "axios";
import TopMenu from "../components/TopMenu";
import Clinics from "./main/Clinics";
import Transcripts from "./main/Transcripts";
import TranscriptsPerClinic from "./main/TranscriptsPerClinic";
import Users from "./main/Users";
import Profile from "./main/Profile";
import { getWithExpiry } from "../custom_functions/getSetWithExpiry";

export default function Main() {
  axios.defaults.headers.common["Authorization"] = getWithExpiry("auth_token");

  const navigate = useNavigate();
  const handleLogout = (e) => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/logout").then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_user");
          navigate("/login");
        }
      });
    });
  };
  useEffect(() => {
    if (!localStorage.getItem("auth_token")) {
      navigate("/login");
    }
  });

  // Media Queries
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));
  const sizes = () => {
    if (desktop) return "large";
    if (tablet) return "medium";
    if (mobile) return "small";
  };

  return (
    <div>
      <TopMenu sizes={sizes} />
      <div className="flex">
        <MainMenu sizes={sizes} handleLogout={handleLogout} />
        <div className="flex-auto h-screen sm:overflow-y-auto mt-10 sm:mt-0 md:mt-0">
          <Routes>
            <Route
              path="/"
              element={
                localStorage.getItem("auth_token") ? (
                  <Navigate replace to="main" />
                ) : null
              }
            />
            <Route path="main/*" element={<Dashboard sizes={sizes} />} />
            <Route path="clinics/*" element={<Clinics />} />
            <Route path="call-recordings/*" element={<Transcripts />} />
            <Route
              path="call-recordings/:clinic_id"
              element={<TranscriptsPerClinic />}
            />
            <Route path="transcripts/*" element={<Transcripts />} />
            <Route
              path="transcripts/:clinic_id"
              element={<TranscriptsPerClinic />}
            />
            <Route
              path="call-tags/*"
              element={<h1 className="text-4xl">Call Tags</h1>}
            />
            {/* <Route
              path="reports/*"
              element={<h1 className="text-4xl">Reports</h1>}
            /> */}
            <Route path="users/*" element={<Users />} />
            <Route path="profile/*" element={<Profile />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
