import { Box, Button, Modal, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import { style } from "../../globalObj/modal";

export default function Users() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const handleInput = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    setOpen(false);
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/user", newUser).then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          getUsers();
        }
      });
    });
  };

  const handleRemoveUser = (e) => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.delete(`/api/user/${e.target.dataset.user_id}`).then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          getUsers();
        }
      });
    });
  };

  const [users, setUsers] = useState([]);
  const getUsers = () => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get("/api/users").then((res) => {
        if (res.status === 200) {
          setUsers(res.data.users);
        }
      });
    });
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="pt-5 pb-5 md:pt-12 px-5">
      <div className="flex justify-end mb-2">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpen}
        >
          <PersonAddAltRoundedIcon />
          Add User
        </Button>
      </div>
      <div className="flex bg-white mb-2 py-2 px-4 shadow">
        <div className="w-2/5 text-left font-semibold">Name</div>
        <div className="w-2/5 text-left font-semibold">Email</div>
      </div>
      <div className="space-y-2">
        {users.map((user) => (
          <div
            className="flex bg-white py-2 px-4 shadow items-center"
            key={`${user.id}_key`}
          >
            <div className="w-2/5 text-left">{user.name}</div>
            <div className="w-2/5 text-left truncate">{user.email}</div>
            <div>
              <Button
                size="small"
                variant="contained"
                color="error"
                data-user_id={user.id}
                onClick={handleRemoveUser}
              >
                Remove
              </Button>
            </div>
          </div>
        ))}
      </div>
      {/* MODAL */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className="text-2xl text-center">Add User</h2>
          <form
            className="flex flex-col justify-between mt-5 mb-5 space-y-2"
            onSubmit={handleCreateUser}
            noValidate
          >
            <TextField
              name="name"
              label="Full Name"
              size="small"
              onChange={handleInput}
            />
            <TextField
              name="email"
              label="Email"
              size="small"
              onChange={handleInput}
            />
            <TextField
              name="password"
              label="Password"
              type="password"
              size="small"
              onChange={handleInput}
            />
            <TextField
              name="password_confirmation"
              label="Confirm Password"
              type="password"
              size="small"
              onChange={handleInput}
            />
            <Button fullWidth variant="contained" color="primary" type="submit">
              Add User
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
