import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ComboBox from "../../components/Autocomplete";
import Clinic from "../../components/Clinic";

export default function Clinics() {
  const [clinics, setClinics] = useState([]);

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      if (!selected) {
        axios.get("/api/clinic/dashboard").then((res) => {
          setClinics(res.data.clinics);
        });
      } else {
        axios.get(`/api/clinic/dashboard?clinic=${selected}`).then((res) => {
          if (res.status === 200) {
            setClinics(res.data.clinics);
          }
        });
      }
    });
  }, [selected]);
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .get("/api/clinic/search")
        .then((res) => {
          setOptions(res.data.clinics);
        })
        .catch((err) => {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_user_email");
          localStorage.removeItem("auth_user_name");
          window.location.replace("/");
        });
    });
  }, []);
  return (
    <div className="px-5 pb-5 pt-5 sm:pt-8 md:pt-12">
      {/* <div className="columns-1 sm:columns-2 md:columns-3 px-12 space-y-2 sm:space-y-0 mb-5"> */}
      <div className="flex mb-4">
        <ComboBox
          options={options}
          setSelected={setSelected}
          label={"Clinic"}
          className="bg-white"
        />
        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleSearchClinic}
        >
          Search Clinic
        </Button> */}
        {/* <Button variant="contained" color="secondary">
          Filter
        </Button> */}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5">
        {clinics.map((clinic) => (
          <Clinic clinic={clinic} key={`${clinic.id}_key`} />
        ))}
      </div>
      <div className="mt-4 md:mt-0 grid grid-cols-1">
        <Button color="primary">View More</Button>
      </div>
    </div>
  );
}
