import {
  Button,
  MenuItem,
  Modal,
  TextField,
  Menu,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { useParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { style } from "../../globalObj/modal";

export default function TranscriptsPerClinic() {
  let { clinic_id } = useParams();

  const columns = [
    { field: "caller_id", headerName: "Caller ID", width: 120 },
    { field: "reason", headerName: "Intent", width: 200 },
    { field: "patient_type", headerName: "Patient Type", width: 200 },
    { field: "transcript", headerName: "Transcript", width: 420 },
  ];

  const [rows, setRows] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState({});
  const handleOpen = (e) => {
    setModalData({
      id: e.target.dataset.id,
      caller_id: e.target.dataset.caller_id,
      reason: e.target.dataset.reason,
      patient_type: e.target.dataset.patient_type,
      transcript: e.target.dataset.transcript,
    });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // Filter
  const selected = clinic_id;

  // Filter
  const [tab, setTab] = useState(0);
  const handleChangeTab = (e) => {
    setTab(parseInt(e.target.dataset.tab));
  };
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    let startDate = `${dates[0].startDate.getFullYear()}-${
      dates[0].startDate.getMonth() + 1
    }-${dates[0].startDate.getDate()}`;
    let endDate = `${dates[0].endDate.getFullYear()}-${
      dates[0].endDate.getMonth() + 1
    }-${dates[0].endDate.getDate()}`;

    let date_filter;
    switch (tab) {
      case 0:
        date_filter = "today";
        break;
      case 1:
        date_filter = "week";
        break;
      case 2:
        date_filter = "month";
        break;
      case 3:
        date_filter = "custom";
        break;
      case 4:
        date_filter = "three_months";
        break;
      case 5:
        date_filter = "six_months";
        break;
      case 6:
        date_filter = "year";
        break;
    }
    if (date_filter !== "custom") {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .get(
            `/api/calls/list?${
              selected ? "clinic=" + selected : ""
            }&date_filter=${date_filter}`
          )
          .then((res) => {
            setRows(res.data.calls);
          });
      });
    } else {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .get(
            `/api/calls/list?${
              selected ? "clinic=" + selected : ""
            }&date_filter=${date_filter}&start=${startDate}&end=${endDate}`
          )
          .then((res) => {
            setRows(res.data.calls);
          });
      });
    }
  }, [tab, dates, selected]);

  const [anchorElMore, setAnchorEl] = useState(null);
  const openMore = Boolean(anchorElMore);
  const handleClickMore = (event) => {
    setTab(parseInt(event.target.dataset.tab));
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMore = (e) => {
    if (e.target.dataset.tab) {
      setTab(parseInt(e.target.dataset.tab));
    }
    setAnchorEl(null);
  };

  // End of Filter

  return (
    <div className="px-5 pb-5 pt-5 sm:pt-8 md:pt-12">
      <div className="flex mb-5 justify-between space-x-1">
        <Button
          variant="outlined"
          color="success"
          sx={{
            color: tab === 0 ? "green" : "gray",
            borderColor: tab === 0 ? "green" : "gray",
          }}
          fullWidth
          data-tab={0}
          onClick={handleChangeTab}
        >
          Today
        </Button>
        <Button
          variant="outlined"
          color="success"
          sx={{
            color: tab === 1 ? "green" : "gray",
            borderColor: tab === 1 ? "green" : "gray",
          }}
          fullWidth
          data-tab={1}
          onClick={handleChangeTab}
        >
          Week
        </Button>
        <Button
          variant="outlined"
          color="success"
          sx={{
            color: tab === 2 ? "green" : "gray",
            borderColor: tab === 2 ? "green" : "gray",
          }}
          fullWidth
          data-tab={2}
          onClick={handleChangeTab}
        >
          Month
        </Button>
        <Button
          variant="outlined"
          color="success"
          sx={{
            color: tab === 3 ? "green" : "gray",
            borderColor: tab === 3 ? "green" : "gray",
          }}
          fullWidth
          data-tab={3}
          onClick={handleClickMore}
        >
          More{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
        <Menu
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorElMore}
          open={openMore}
          onClose={handleCloseMore}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleCloseMore} disableRipple data-tab={4}>
            PAST 90 DAYS
          </MenuItem>
          <hr />
          <MenuItem onClick={handleCloseMore} disableRipple data-tab={5}>
            PAST 180 DAYS
          </MenuItem>
          <hr />
          <MenuItem onClick={handleCloseMore} disableRipple data-tab={6}>
            PAST 365 DAYS
          </MenuItem>
          <hr />
          <Accordion style={{ boxShadow: "none" }}>
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>CUSTOM</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DateRange
                onChange={(item) => setDates([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                showDateDisplay={false}
                months={1}
                ranges={dates}
                maxDate={new Date()}
                direction="horizontal"
              />
            </AccordionDetails>
          </Accordion>
        </Menu>
      </div>
      <DataTable columns={columns} rows={rows} handleOpen={handleOpen} />

      {/* MODAL for transcript*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex mt-5 mb-2 space-x-2">
            <div>
              <TextField
                label="Caller ID"
                value={modalData.caller_id}
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
              />
            </div>
            <div>
              <TextField
                label="Call Intent"
                value={modalData.reason}
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
              />
            </div>
          </div>
          <div className="flex mt-5 mb-2 space-x-2">
            <div className="mb-2">
              <TextField
                fullWidth
                label="Patient Type"
                defaultValue={modalData.patient_type}
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
              />
            </div>
          </div>
          <div className="mb-2">
            <p className="text-sm">Transcript</p>
            <div className="overflow-y-auto h-80 bg-blue-50 p-2 border rounded-md">
              {modalData.transcript}
            </div>
          </div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
