// import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Main from "./views/Main";
import Login from "./views/auth/Login";
import ResetPassword from "./views/auth/ResetPassword";
import axios from "axios";
import { getWithExpiry } from "./custom_functions/getSetWithExpiry";

// Axios Setup
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.baseURL = "https://api-staging-call-tagging.dentistfind.com";
// axios.defaults.baseURL = "https://api-call-tagging.dentistfind.com";
// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = getWithExpiry("auth_token");

axios.defaults.withCredentials = true;

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              localStorage.getItem("auth_token") ? (
                <Navigate replace to="/dashboard" />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />
          <Route path="/login" element={<Login />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/dashboard/*" element={<Main />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
