import React from "react";

export default function ClinicCard({ clinic }) {
  return (
    <div>
      <h3
        className="text-white p-4 text-xl md:text-2xl font-semibold"
        style={{ backgroundColor: "rgb(0, 172, 140)" }}
      >
        {clinic.clinic.toUpperCase()}
      </h3>
      <div className="flex flex-col">
        <div className="flex">
          <div
            className="flex flex-col justify-center items-center cursor-default w-1/2 h-40 text-white hover:scale-105 hover:shadow-2xl transition ease-in duration-150"
            style={{ backgroundColor: "rgb(243, 122, 0)" }}
          >
            <p className="text-2xl md:text-3xl font-semibold flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
              </svg>
              {clinic.new}
            </p>
            <h4 className="text-sm md:text-lg">Potential Patients</h4>
          </div>
          <div
            className="flex flex-col justify-center items-center cursor-default w-1/2 h-40 text-white hover:scale-105 hover:shadow-2xl transition ease-in duration-150"
            style={{ backgroundColor: "rgb(0, 134, 216)" }}
          >
            <p className="text-2xl md:text-3xl font-semibold flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                />
              </svg>
              {clinic.existing}
            </p>
            <h4 className="text-sm md:text-lg">Existing Patients</h4>
          </div>
        </div>
        <div className="flex">
          <div
            className="flex flex-col justify-center items-center cursor-default w-1/2 h-40 text-white hover:scale-105 hover:shadow-2xl transition ease-in duration-150"
            style={{ backgroundColor: "rgb(68, 67, 186)" }}
          >
            <p className="text-2xl md:text-3xl font-semibold flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-1"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <line x1="18" y1="8" x2="23" y2="13"></line>
                <line x1="23" y1="8" x2="18" y2="13"></line>
              </svg>
              {clinic.non_patient_related}
            </p>
            <h4 className="text-sm md:text-lg">Non Patient Related</h4>
          </div>
          <div
            className="flex flex-col justify-center items-center cursor-default w-1/2 h-40 text-white hover:scale-105 hover:shadow-2xl transition ease-in duration-150"
            style={{ backgroundColor: "rgb(194, 0, 22)" }}
          >
            <p className="text-2xl md:text-3xl font-semibold flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {clinic.unknown}
            </p>
            <h4 className="text-sm md:text-lg">Unknown</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
