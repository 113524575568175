import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

export default function MainMenu({ sizes }) {
  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  const [loggingOut, setLoggingOut] = useState(false);
  const [logoutButton, setLogoutButton] = useState(
    <LogoutIcon style={{ color: "white" }} />
  );

  const navigate = useNavigate();
  const handleLogout = (e) => {
    setLogoutButton(<CircularProgress color="success" size={25} />);
    setLoggingOut(true);
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/logout").then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_user_email");
          localStorage.removeItem("auth_user_name");
          navigate("/login");
        }
      });
    });
  };
  const email = getWithExpiry("auth_user_email");
  const name = getWithExpiry("auth_user_name");

  let location = useLocation();

  return (
    <div
      className={`flex-none flex flex-col justify-between h-screen w-48 md:w-64 pt-10 ${
        sizes() === "small" ? "hidden" : ""
      }`}
      // style={{ backgroundColor: "rgb(51, 51, 51)" }}
      style={{ backgroundColor: "rgb(0, 172, 140)" }}
    >
      <div className="flex flex-col text-left font-semibold text-sm md:text-lg text-white">
        <h2 className="text-2xl text-center">DentistFind</h2>
        <h2 className="mb-5 text-center">Call Tagging Automation</h2>
        <Link
          to="main"
          className={`pl-5 md:pl-10 py-2 ${
            location.pathname.includes("/dashboard/main")
              ? "bg-gray-600/25"
              : "hover:bg-gray-600/25"
          }`}
        >
          Dashboard
        </Link>
        <Link
          to="clinics"
          className={`pl-5 md:pl-10 py-2 ${
            location.pathname.includes("/dashboard/clinics")
              ? "bg-gray-600/25"
              : "hover:bg-gray-600/25"
          }`}
        >
          Clinics
        </Link>
        <div className="flex flex-col">
          {/* <Link
            to="call-recordings"
            className={`pl-8 md:pl-16 py-1 ${
              location.pathname.includes("/dashboard/call-recordings")
                ? "bg-gray-600/25"
                : "hover:bg-gray-600/25"
            }`}
          >
            Call Recordings
          </Link> */}
          <Link
            to="transcripts"
            className={`pl-8 md:pl-16 py-1 ${
              location.pathname.includes("/dashboard/transcripts")
                ? "bg-gray-600/25"
                : "hover:bg-gray-600/25"
            }`}
          >
            Transcripts
          </Link>
          {/* <Link
            to="call-tags"
            className={`pl-8 md:pl-16 py-1 ${
              location.pathname.includes("/dashboard/call-tags")
                ? "bg-gray-600/25"
                : "hover:bg-gray-600/25"
            }`}
          >
            Call Tags
          </Link> */}
        </div>
        {/* <Link
          to="reports"
          className={`pl-5 md:pl-10 py-2 ${
            location.pathname.includes("/dashboard/reports")
              ? "bg-gray-600/25"
              : "hover:bg-gray-600/25"
          }`}
        >
          Reports
        </Link> */}
        <Link
          to="users"
          className={`pl-5 md:pl-10 py-2 ${
            location.pathname.includes("/dashboard/users")
              ? "bg-gray-600/25"
              : "hover:bg-gray-600/25"
          }`}
        >
          Users
        </Link>
      </div>
      <div
        className={
          "flex justify-between m-4 p-4 shadow-md bg-gray-600/25 hover:bg-emerald-400/50"
        }
        // style={{ backgroundColor: "rgba(89, 89, 89, 0.4)" }}
      >
        <Link
          to="/dashboard/profile"
          className="flex flex-col items-start justify-center"
        >
          <p className="w-24 md:w-auto text-left text-white font-semibold truncate">
            {name}
          </p>
          <p className="w-24 md:w-auto text-left text-white text-xs truncate">
            {email}
          </p>
        </Link>
        <div
          onClick={handleLogout}
          className={`px-2 flex justify-center items-center ${
            loggingOut ? "" : "hover:bg-emerald-400"
          } rounded-full cursor-pointer`}
        >
          {logoutButton}
        </div>
      </div>
    </div>
  );
}
