import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import ComboBox from "../../components/Autocomplete";
import ClinicCard from "../../components/ClinicCard";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

// Date range imports
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { getWithExpiry } from "../../custom_functions/getSetWithExpiry";

import moment from "moment";
import { set } from "date-fns/esm";

export default function Dashboard({ sizes }) {
  const [clinics, setClinics] = useState([]);

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const [selectedLabel, setSelectedLabel] = useState();

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSearchClinic = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then((response) => {
      if (!selected) {
        axios.get("/api/clinic/dashboard").then((res) => {
          setClinics(res.data.clinics);
        });
      } else {
        axios.get(`/api/clinic/dashboard?clinic=${selected}`).then((res) => {
          if (res.status === 200) {
            setClinics(res.data.clinics);
          }
        });
      }
    });
  };

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      // axios.get("/api/clinic/dashboard").then((res) => {
      //   setClinics(res.data.clinics);
      // });
      axios
        .get("/api/clinic/search")
        .then((res) => {
          setOptions(res.data.clinics);
        })
        .catch((err) => {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_user_email");
          localStorage.removeItem("auth_user_name");
          window.location.replace("/");
        });
    });
  }, []);

  // Filter
  const [tab, setTab] = useState(0);
  const handleChangeTab = (e) => {
    setTab(parseInt(e.target.dataset.tab));
    setTimeDelta(parseInt(e.target.dataset.time_delta));
    setTabTag(e.target.dataset.tabtag);
    setIsCustom(false);
  };

  // For Downloading reports
  const [reportsQuery, setReportsQuery] = useState("");
  // =======================

  useEffect(() => {
    let startDate = `${dates[0].startDate.getFullYear()}-${
      dates[0].startDate.getMonth() + 1
    }-${dates[0].startDate.getDate()}`;
    let endDate = `${dates[0].endDate.getFullYear()}-${
      dates[0].endDate.getMonth() + 1
    }-${dates[0].endDate.getDate()}`;

    let date_filter;
    switch (tab) {
      case 0:
        date_filter = "today";
        break;
      case 1:
        date_filter = "week";
        break;
      case 2:
        date_filter = "month";
        break;
      case 3:
        date_filter = "custom";
        break;
      case 4:
        date_filter = "three_months";
        break;
      case 5:
        date_filter = "six_months";
        break;
      case 6:
        date_filter = "year";
        break;
    }
    if (date_filter !== "custom") {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .get(
            `/api/clinic/dashboard?${
              selected ? "clinic=" + selected : ""
            }&date_filter=${date_filter}`
          )
          .then((res) => {
            setClinics(res.data.clinics);
          });
      });

      setReportsQuery(
        `${process.env.REACT_APP_API_URL}/export/call-transcripts?${
          selected ? "clinic=" + selected : ""
        }&date_filter=${date_filter}`
      );
    } else {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .get(
            `/api/clinic/dashboard?${
              selected ? "clinic=" + selected : ""
            }&date_filter=${date_filter}&start=${startDate}&end=${endDate}`
          )
          .then((res) => {
            setClinics(res.data.clinics);
          });
      });
      setReportsQuery(
        `${process.env.REACT_APP_API_URL}/export/call-transcripts?${
          selected ? "clinic=" + selected : ""
        }&date_filter=${date_filter}&start=${startDate}&end=${endDate}`
      );
    }
  }, [tab, dates, selected]);

  const [anchorElMore, setAnchorEl] = useState(null);
  const [timeDelta, setTimeDelta] = useState(0);
  const [tabTag, setTabTag] = useState("");
  const [isCustom, setIsCustom] = useState(false);
  const openMore = Boolean(anchorElMore);
  const handleClickMore = (event) => {
    setTab(parseInt(event.target.dataset.tab));
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMore = (e) => {
    if (e.target.dataset.tab) {
      setTimeDelta(parseInt(e.target.dataset.time_delta));
      setTab(parseInt(e.target.dataset.tab));
      setTabTag("days");
      setIsCustom(false);
    }
    setAnchorEl(null);
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  const [chartData, setChartData] = useState(null);
  const [totalTags, setTotalTags] = useState(0);
  useEffect(() => {
    let new_patients = 0;
    let existing_patients = 0;
    let non_patient_related = 0;
    let unknown = 0;
    let total = 0;
    clinics.forEach((clinic) => {
      new_patients += parseInt(clinic.new);
      existing_patients += parseInt(clinic.existing);
      non_patient_related += parseInt(clinic.non_patient_related);
      unknown += parseInt(clinic.unknown);
    });
    total = new_patients + existing_patients + non_patient_related + unknown;
    setTotalTags(total);
    setChartData({
      labels: [
        "Unknown",
        "Non Patient Related",
        "Existing Patients",
        "Potential Patients",
      ],
      datasets: [
        {
          label: "",
          data: [unknown, non_patient_related, existing_patients, new_patients],
          backgroundColor: [
            "rgb(194, 0, 22)",
            "rgb(68, 67, 186)",
            "rgb(0, 134, 216)",
            "rgb(243, 122, 0)",
          ],
          borderColor: [
            "rgb(194, 0, 22)",
            "rgb(68, 67, 186)",
            "rgb(0, 134, 216)",
            "rgb(243, 122, 0)",
          ],
          borderWidth: 1,
        },
      ],
    });
  }, [clinics]);

  const downloadReport = () => {
    let startDate = `${dates[0].startDate.getFullYear()}-${
      dates[0].startDate.getMonth() + 1
    }-${dates[0].startDate.getDate()}`;
    let endDate = `${dates[0].endDate.getFullYear()}-${
      dates[0].endDate.getMonth() + 1
    }-${dates[0].endDate.getDate()}`;

    let date_filter;
    switch (tab) {
      case 0:
        date_filter = "today";
        break;
      case 1:
        date_filter = "week";
        break;
      case 2:
        date_filter = "month";
        break;
      case 3:
        date_filter = "custom";
        break;
      case 4:
        date_filter = "three_months";
        break;
      case 5:
        date_filter = "six_months";
        break;
      case 6:
        date_filter = "year";
        break;
    }

    let queryString;
    if (date_filter !== "custom") {
      queryString = `/export/call-transcripts?${
        selected ? "clinic=" + selected : ""
      }&date_filter=${date_filter}`;
    } else {
      queryString = `/export/call-transcripts?${
        selected ? "clinic=" + selected : ""
      }&date_filter=${date_filter}&start=${startDate}&end=${endDate}`;
    }

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(queryString).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "transcripts.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    });
  };
  return (
    <div className="px-5 pb-5 pt-5 sm:pt-8 md:pt-12">
      <form
        onSubmit={handleSearchClinic}
        className="flex items-center mb-4 space-x-1"
      >
        <ComboBox
          options={options}
          label={"Clinic"}
          setSelected={setSelected}
          className="bg-white"
          setSelectedLabel={setSelectedLabel}
        />
        <a download href={reportsQuery}>
          <Button variant="outlined">Export</Button>
        </a>
      </form>
      <div className="flex mb-5 justify-between space-x-1">
        <Button
          variant="outlined"
          color="success"
          sx={{
            color: tab === 0 ? "green" : "gray",
            borderColor: tab === 0 ? "green" : "gray",
          }}
          fullWidth
          data-tab={0}
          data-time_delta={0}
          data-tabtag={"days"}
          onClick={handleChangeTab}
        >
          Today
        </Button>
        <Button
          variant="outlined"
          color="success"
          sx={{
            color: tab === 1 ? "green" : "gray",
            borderColor: tab === 1 ? "green" : "gray",
          }}
          fullWidth
          data-tab={1}
          data-time_delta={1}
          data-tabtag={"weeks"}
          onClick={handleChangeTab}
        >
          Week
        </Button>
        <Button
          variant="outlined"
          color="success"
          sx={{
            color: tab === 2 ? "green" : "gray",
            borderColor: tab === 2 ? "green" : "gray",
          }}
          fullWidth
          data-tab={2}
          data-time_delta={1}
          data-tabtag={"months"}
          onClick={handleChangeTab}
        >
          Month
        </Button>
        <Button
          variant="outlined"
          color="success"
          sx={{
            color: tab >= 3 ? "green" : "gray",
            borderColor: tab >= 3 ? "green" : "gray",
          }}
          fullWidth
          data-tab={3}
          onClick={handleClickMore}
        >
          More{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
        <Menu
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorElMore}
          open={openMore}
          onClose={handleCloseMore}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={handleCloseMore}
            disableRipple
            data-tab={4}
            data-time_delta={90}
          >
            PAST 90 DAYS
          </MenuItem>
          <hr />
          <MenuItem
            onClick={handleCloseMore}
            disableRipple
            data-tab={5}
            data-time_delta={180}
          >
            PAST 180 DAYS
          </MenuItem>
          <hr />
          <MenuItem
            onClick={handleCloseMore}
            disableRipple
            data-tab={6}
            data-time_delta={365}
          >
            PAST 365 DAYS
          </MenuItem>
          <hr />
          <Accordion style={{ boxShadow: "none" }}>
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>CUSTOM</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DateRange
                onChange={(item) => {
                  setDates([item.selection]);
                  setIsCustom(true);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                showDateDisplay={false}
                months={1}
                ranges={dates}
                maxDate={new Date()}
                direction="horizontal"
              />
            </AccordionDetails>
          </Accordion>
        </Menu>
      </div>
      <p className="mb-2 text-xl">Call Tagging Analytics</p>

      {isCustom ? (
        <p>
          {dates[0].startDate.toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })}{" "}
          -{" "}
          {dates[0].endDate.toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })}
        </p>
      ) : timeDelta > 0 ? (
        tabTag === "days" ? (
          <p>
            {moment().subtract(timeDelta, tabTag).format("MMM DD, YYYY")} -{" "}
            {moment().format("MMM DD, YYYY")}
          </p>
        ) : (
          <p>
            {moment().startOf(tabTag).format("MMM DD, YYYY")} -{" "}
            {moment().format("MMM DD, YYYY")}
          </p>
        )
      ) : (
        <p>{moment().format("MMM DD, YYYY")}</p>
      )}

      {chartData ? (
        <div className="flex justify-center mt-2">
          <div className="mb-5 flex justify-center w-3/4 relative">
            <p className="absolute top-1/3 text-4xl font-semibold">
              {totalTags}
            </p>
            <p className="absolute top-1/3 text-md mt-10">LOGS</p>
            <Doughnut
              data={chartData}
              width="200px"
              height="200px"
              options={{
                plugins: { legend: { display: false } },
                maintainAspectRatio: false,
                cutout: 70,
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5">
        {clinics.map((clinic) => (
          <ClinicCard clinic={clinic} key={`${clinic.id}_key`} />
        ))}
      </div>
      <div className="mt-4 md:mt-0 grid grid-cols-1">
        <Button color="primary">View More</Button>
      </div>
    </div>
  );
}
