import * as React from "react";
import moment from "moment";
import { Skeleton } from "@mui/material";

export default function DataTable({ rows, handleOpen }) {
  const convertDate = (date) => {
    let formatDate = new Date(date);
    return moment(formatDate).format("MMM Do YYYY, h:mm:ss a");
  };

  return (
    <div className="h-5/6">
      <div className="flex bg-white shadow-md rounded mb-1 items-center justify-evenly pl-2 pr-2">
        <div className="w-1/4 pl-2 py-2 text-left text-sm md:text-md font-semibold">
          Caller ID
        </div>
        <div className="w-1/4 pl-2 py-2 text-left text-sm md:text-md font-semibold">
          Call Date
        </div>
        <div className="w-1/4 pl-2 py-2 text-left text-sm md:text-md font-semibold">
          Patient Type
        </div>
        <div className="w-1/4 pl-2 py-2 text-left text-sm md:text-md font-semibold">
          Category
        </div>
        <div className="w-1/4 pl-2 py-2 text-left text-sm md:text-md font-semibold">
          Reason
        </div>
        <div className="w-1/4 pl-2 py-2 text-left text-sm md:text-md font-semibold">
          Booking Status
        </div>
        <div className="w-1/4 pl-2 py-2 text-left text-sm md:text-md font-semibold"></div>
      </div>
      <div className="h-full overflow-y-auto no-scrollbar">
        {rows.isLoading ? (
          <div className="flex bg-white hover:bg-gray-50 shadow-md rounded mb-1 items-center justify-evenly px-2">
            <div className="w-1/4 pl-2 py-2 text-left">
              <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
            </div>
            <div className="w-1/4 pl-2 py-2 text-left">
              <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
            </div>
            <div className="w-1/4 pl-2 py-2 text-left">
              <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
            </div>
            <div className="w-1/4 pl-2 py-2 text-left">
              <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
            </div>
            <div className="w-1/4 pl-2 py-2 text-left">
              <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
            </div>
            <div className="w-1/4 pl-2 py-2 text-left">
              <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
            </div>
            <div className="w-1/4 pl-2 py-2 flex justify-end"></div>
          </div>
        ) : rows?.data.length === 0 && !rows.isLoading ? (
          <div className="flex bg-white text-gray-400 hover:bg-gray-50 shadow-md rounded mb-1 items-center justify-evenly px-2 py-2">
            No Call Logs
          </div>
        ) : (
          rows?.data?.map((row) => (
            <div
              className="flex bg-white hover:bg-gray-50 shadow-md rounded mb-1 items-center justify-evenly px-2"
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <div className="w-1/4 pl-2 py-2 text-left">
                <p className="text-sm md:text-md">{row.caller_id}</p>
              </div>
              <div className="w-1/4 pl-2 py-2 text-left">
                <p className="text-sm">{convertDate(row.created_at)}</p>
              </div>
              <div className="w-1/4 pl-2 py-2 text-left">
                <p className="text-sm md:text-md">{row.patient_type}</p>
              </div>
              <div className="w-1/4 pl-2 py-2 text-left">
                <p className="text-sm md:text-md">
                  {row.call_tags?.[0]?.category}
                </p>
              </div>
              <div className="w-1/4 pl-2 py-2 text-left">
                <p className="text-sm md:text-md">
                  {row.call_tags?.[0]?.reason}
                </p>
              </div>
              <div
                className={`w-1/4 pl-2 py-2 text-left ${
                  row.booking_status ? "bg-green-400" : ""
                }`}
              >
                <p className="text-sm md:text-md">
                  {row.booking_status
                    ? "Booked"
                    : row.booking_status === false
                    ? "Not Booked"
                    : "N/A"}
                </p>
              </div>
              <div className="w-1/4 pl-2 py-2 flex justify-end">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white text-sm md:text-md shadow py-1 px-4 rounded-sm"
                  name="transcript"
                  onClick={handleOpen}
                  data-id={row.id}
                  data-caller_id={row.caller_id}
                  data-reason={row.call_tags?.[0]?.reason}
                  data-category={row.call_tags?.[0]?.category}
                  data-patient_type={row.patient_type}
                  data-transcript={row.transcript}
                  data-booking_status={row.booking_status}
                >
                  Show
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
