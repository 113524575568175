import React from "react";
import {
  Avatar,
  // Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function TopMenu({ sizes }) {
  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
  const name = getWithExpiry("auth_user_name");
  const navigate = useNavigate();
  const handleLogout = (e) => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/logout").then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_user_email");
          localStorage.removeItem("auth_user_name");
          navigate("/login");
        }
      });
    });
  };

  const handleNav = (e) => {
    navigate(e.target.dataset.link);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const menu = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <h1 className="text-center text-lg font-bold mt-5">Menu</h1>
      <List>
        <ListItem
          button
          key="dashboard"
          href="/dashboard/main"
          component={Link}
        >
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem
          button
          key="clinics"
          href="/dashboard/clinics"
          component={Link}
          className="bg-blue-200"
        >
          <ListItemText primary={"Clinics"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        {/* <ListItem
          button
          key="call-recordings"
          href="/dashboard/call-recordings"
          component={Link}
        >
          <ListItemText primary={"Call Recordings"} />
        </ListItem> */}
        <ListItem
          button
          key="transcripts"
          href="/dashboard/transcripts"
          component={Link}
        >
          <ListItemText primary={"Transcripts"} />
        </ListItem>
        {/* <ListItem
          button
          key="call-tags"
          href="/dashboard/call-tags"
          component={Link}
        >
          <ListItemText primary={"Call Tags"} />
        </ListItem> */}
      </List>
      <Divider />
      {/* <List>
        <ListItem
          button
          key="reports"
          href="/dashboard/reports"
          component={Link}
        >
          <ListItemText primary={"Reports"} />
        </ListItem>
      </List> */}
      <Divider />
      <List>
        <ListItem button key="users" href="/dashboard/users" component={Link}>
          <ListItemText primary={"Users"} />
        </ListItem>
      </List>
    </Box>
  );

  // Account Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="fixed top-0 z-10 w-full">
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {menu(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
      <div
        className={`flex items-center justify-between px-4 h-10 sticky top-0 z-10 shadow-md ${
          sizes() !== "small" ? "hidden" : ""
        }`}
        style={{ backgroundColor: "rgb(0, 172, 140)" }}
      >
        <MenuIcon
          fontSize="large"
          className="text-white hover:bg-green-500 rounded"
          onClick={toggleDrawer("left", true)}
        />
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{name[0]}</Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleNav} data-link="/dashboard/profile">
            <Avatar /> Profile
          </MenuItem>
          <MenuItem>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem>Settings</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
}
