import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function Clinic({ clinic }) {
  return (
    <div className="bg-white shadow-lg">
      <h3 className="p-4 text-xl md:text-2xl font-semibold">
        {clinic.clinic.toUpperCase()}
      </h3>
      <div className="py-1 px-2">
        {/* <Link to={`/dashboard/call-recordings/${clinic.id}`}>
          <Button variant="outlined" fullWidth>
            Call Recordings
          </Button>
        </Link> */}
        <Link to={`/dashboard/transcripts/${clinic.id}`}>
          <Button variant="outlined" fullWidth>
            Transcripts
          </Button>
        </Link>
        <div>
          <Button variant="outlined" fullWidth>
            Call Tags
          </Button>
        </div>
      </div>
    </div>
  );
}
